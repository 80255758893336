import * as React from 'react'

import { Trash } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { isStandardEmbroideryItem } from '@syconium/magnolia/src/lib/utils'

import { useSiteWideMessaging } from '../../../../app/_providers/SiteWideMessagingProvider.client'
import { CartItem } from '../../../../types/figs'
import { EmbroideryCartItem } from '../CartItem/EmbroideryCartItem'
import { KitCartItem } from '../CartItem/KitCartItem'
import { PriceAndDeleteButton } from '../CartItem/PriceAndDeleteButton'
import { DeleteIconButton, DeleteIconColumn } from '../CartItem/PriceAndDeleteButton/styles'
import { CartItemQuantity } from '../CartItemQuantity'
import { sortCartItems } from '../utils'

import {
  Body,
  Details,
  Items,
  PriceSection,
  QuantityWrapper,
  SiteWidePromoWrap,
  Title,
} from './styles'

export const Template: React.FC<{
  cartIsUpdating: boolean
  cartItems: ReadonlyArray<CartItem>
  decrementQuantity(): void
  embroideryLabel: string
  eventCategory: 'minicart' | 'cart'
  incrementQuantity(): void
  removeAll(): void
  removeItem(key: string): void
  setQuantity(newQuantity: number): void
}> = ({
  cartIsUpdating,
  cartItems,
  decrementQuantity,
  embroideryLabel,
  eventCategory,
  incrementQuantity,
  removeAll,
  removeItem,
  setQuantity,
}) => {
  const { siteWidePromotionMessage: siteWidePromo } = useSiteWideMessaging()
  const firstItem = cartItems.find(item => item.properties['_kit'])
  const kitTitle = firstItem?.properties['_kit'] || ''

  const discountPct = parseFloat(
    cartItems.filter(item => item.productType !== 'Embroidery')?.[0]?.properties[
      '_kitDiscountPct'
    ] ?? ''
  )

  // combined price on the kit group level excludes embroidery items in the group
  // because those price are listed separately and included in the grand total
  const combinedFullPrice = cartItems.reduce(
    (total, item) =>
      total +
      (isStandardEmbroideryItem(item) ? 0 : discountPct ? item.fullPrice : item.effectivePrice),
    0
  )

  const validDiscountPct = !isNaN(discountPct) && discountPct > 0 && discountPct <= 100
  const swPromoEligible = cartItems[0]?.properties._swPromoEligible === 'true' ? true : false

  const combinedEffectivePrice = validDiscountPct
    ? Math.round(combinedFullPrice * ((100 - discountPct) / 100))
    : combinedFullPrice

  return (
    <Body data-testid='kit-group' data-cart-item-id={cartItems[0]?.properties._setUid}>
      <Details>
        <Title>{kitTitle}</Title>
        <DeleteIconColumn>
          <DeleteIconButton
            {...trackEvent({
              category: eventCategory,
              action: 'remove item from cart',
              label: kitTitle,
            })}
            onClick={removeAll}
            aria-label={`Remove item: ${kitTitle}`}
          >
            <Trash stroke='currentColor' />
          </DeleteIconButton>
        </DeleteIconColumn>
      </Details>

      {siteWidePromo && swPromoEligible && <SiteWidePromoWrap>{siteWidePromo}</SiteWidePromoWrap>}

      <Details>
        <QuantityWrapper>
          <CartItemQuantity
            decrementQuantity={decrementQuantity}
            eventCategory={eventCategory}
            incrementQuantity={incrementQuantity}
            setQuantity={setQuantity}
            quantity={firstItem?.quantity || 0}
            cartIsUpdating={cartIsUpdating}
          />
        </QuantityWrapper>
        <PriceSection>
          <PriceAndDeleteButton
            displayDiscountAsValue={true}
            fullPrice={combinedFullPrice}
            effectivePrice={combinedEffectivePrice}
          />
        </PriceSection>
      </Details>

      <Items>
        {cartItems.length > 0 &&
          sortCartItems(cartItems, false).map((item, index) => {
            const key = item.sku + index
            if (isStandardEmbroideryItem(item)) {
              return (
                <EmbroideryCartItem
                  cartItemProperties={item.properties}
                  eventCategory='minicart'
                  key={key}
                  inKit={true}
                  label={embroideryLabel}
                  effectivePrice={item.effectivePrice}
                  fullPrice={item.fullPrice}
                  removeItem={() => removeItem(item.key)}
                  title={item.title}
                />
              )
            } else {
              return <KitCartItem key={key} cartItem={item} eventCategory={eventCategory} />
            }
          })}
      </Items>
    </Body>
  )
}
