'use client'

import { useCallback, useEffect, useMemo, useState } from 'react'
import LazyHydrate from 'react-lazy-hydration'

import { Animate } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import {
  formatPrettyCurrency,
  intlPrettyCurrency,
  isStandardEmbroideryItem,
} from '@syconium/magnolia/src/lib/utils'

import { reportClientError } from '../../../app/_components/chrome/scripts/DataDogRumScript'
import { useFeatureFlag } from '../../../app/_providers/ExperimentationProvider.client'
import { useLocalization } from '../../../app/_providers/LocalizationProvider.client'
import { usePortal } from '../../../app/_providers/PortalProvider.client'
import { useSiteWideMessaging } from '../../../app/_providers/SiteWideMessagingProvider.client'
import { useTranslation } from '../../../app/_providers/TranslationProvider.client'
import { CartContainer } from '../../containers/cart'
import { KitGroup } from '../Cart'
import {
  CheckoutButtonWrapper,
  EmbroideryWarningContainer,
  EmptyCartMessage,
  MiniCartMessage,
  TotalSection,
} from '../Cart/styles'
import { groupKitItems, sortCartItems } from '../Cart/utils'
import { CtaButton } from '../CtaButton'
import { EmbroideryDisclosure } from '../EmbroideryDisclosure'
import { IntlShippingAndTaxMsg } from '../IntlShippingAndTaxMsg'

import { FreeShippingSection } from './FreeShipping'
import { MiniCartItem } from './MiniCartItem'
import {
  CartItemsWrapper,
  CheckoutErrorMessage,
  IntlMsgWrap,
  PortalDiscountText,
  TotalAndCheckout,
  TotalLineItems,
} from './styles'
import { TotalLine } from './TotalLine'
import { Upsells } from './Upsells'

/**
 * Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.
 * @deprecated do not use outside of temporary Product Bundle Discounts work.
 * @todo remove this after we remove this feature.
 */
const rlsusProductGroupHandle = 'womens-ribbed-longsleeve-underscrub' as const

type MiniCartContentProps = {
  isRevealed: boolean
}

export const MiniCartContent = ({ isRevealed }: MiniCartContentProps) => {
  const {
    cart,
    status: cartStatus,
    proceedToCheckout,
    removeItems,
    updateItemsQuantity,
  } = CartContainer.useContainer()

  const { cart: miniCartCopy } = useTranslation()
  const { siteWidePrecheckoutMessage: miniCartMessage } = useSiteWideMessaging()
  const { locale, region } = useLocalization()
  const { portal, stipend } = usePortal()

  const [isCheckoutInFlight, setIsCheckoutInFlight] = useState<boolean>(false)
  const [checkoutFailed, setCheckoutFailed] = useState<boolean>(false)

  const cartItems = Object.values(cart.items)

  const kitGroups = groupKitItems(cartItems)

  const formattedPrice = intlPrettyCurrency({
    cents: cart.totalPrice,
    region: region,
    locale: locale,
    explicitFormat: true,
  })

  useEffect(() => {
    if (!isRevealed) {
      setCheckoutFailed(false)
    }
  }, [isRevealed])

  const handleCheckoutClick = useCallback(async () => {
    try {
      setIsCheckoutInFlight(true)
      setCheckoutFailed(false)
      await proceedToCheckout()
      setIsCheckoutInFlight(false)
    } catch (error) {
      setCheckoutFailed(true)
      setIsCheckoutInFlight(false)
      reportClientError({
        error,
        context: {
          scope: 'MiniCart',
        },
      })
    }
  }, [proceedToCheckout])

  const doesCartContainAStandardEmbroideryItem: boolean = Object.values(cart?.items || {}).some(
    item => isStandardEmbroideryItem(item)
  )

  const checkoutButton: JSX.Element = (
    <CtaButton
      allCaps={true}
      block
      data-testid='checkout-button'
      borderless={cartItems.length === 0 || isCheckoutInFlight}
      disabled={cartItems.length === 0 || isCheckoutInFlight}
      isProcessing={isCheckoutInFlight}
      onClick={handleCheckoutClick}
      {...trackEvent({
        category: 'minicart',
        action: 'checkout',
        label: miniCartCopy.checkoutCTA,
        value: cart.totalPrice,
      })}
    >
      {miniCartCopy.checkoutCTA}
    </CtaButton>
  )

  const hasPortalItemsSelected = useMemo(() => {
    return cartItems.filter(item => item.isPortalColor).length > 0
  }, [cartItems])

  /**
   * Start :: Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.
   * @deprecated do not use outside of temporary Product Bundle Discounts work.
   * @todo remove this after we remove this feature.
   */
  const hasEnoughRLSUS = useMemo(() => {
    const rlsusCount = cartItems.reduce((accumulator, current) => {
      if (current.productGroupHandle === rlsusProductGroupHandle) {
        accumulator += current.quantity
      }
      return accumulator
    }, 0)
    return rlsusCount >= 3
  }, [cartItems])

  const productBundleDiscountCopyFlag = useFeatureFlag({
    key: 'enable-product-bundle-discount-copy-text',
    defaultVariant: 'hide-discount-copy',
    ifAccessedPriorToDecisionInitialization: 'return-null-while-pending',
    skip: !hasEnoughRLSUS,
  })

  const showDiscountAtCheckoutCopy = useMemo(() => {
    return productBundleDiscountCopyFlag === 'show-discount-copy'
  }, [productBundleDiscountCopyFlag])

  // End :: Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.

  const hasStipend = portal && stipend !== null

  return (
    <>
      <FreeShippingSection cartTotal={cart.totalPrice} />
      {cart && Object.keys(cart.items).length > 0 ? (
        <CartItemsWrapper>
          {kitGroups.map(group => {
            const key = group.map(o => o.key).join('')
            return (
              <KitGroup
                key={key}
                cartItems={group}
                eventCategory='minicart'
                embroideryLabel={miniCartCopy.embroideryItemLabel}
                cartIsUpdating={cartStatus === 'pending'}
                decrementQuantity={() => {
                  updateItemsQuantity(
                    group.map(subItem => subItem.key),
                    -1
                  )
                }}
                incrementQuantity={() => {
                  updateItemsQuantity(
                    group.map(subItem => subItem.key),
                    1
                  )
                }}
                setQuantity={(newQuantity: number) => {
                  updateItemsQuantity(
                    group.map(subItem => subItem.key),
                    () => newQuantity
                  )
                }}
                removeAll={() => {
                  removeItems(group.map(subItem => subItem.key))
                }}
                removeItem={(key: string) => removeItems([key])}
              />
            )
          })}
          {sortCartItems(cartItems).map((item, index) => (
            <MiniCartItem
              key={`${item.key}-${index}`}
              item={item}
              index={index}
              showDiscountAtCheckoutCopy={
                item.productGroupHandle === rlsusProductGroupHandle && showDiscountAtCheckoutCopy
              }
            />
          ))}
        </CartItemsWrapper>
      ) : (
        <Animate.FadeIn>
          <EmptyCartMessage>{miniCartCopy.emptyCartMessage}</EmptyCartMessage>
        </Animate.FadeIn>
      )}

      <TotalAndCheckout>
        {cart && (
          <TotalSection>
            <EmbroideryWarningContainer>
              {doesCartContainAStandardEmbroideryItem && (
                <EmbroideryDisclosure style={{ marginBottom: '20px' }} />
              )}
            </EmbroideryWarningContainer>
            {miniCartMessage && <MiniCartMessage>{miniCartMessage}</MiniCartMessage>}
            <TotalLineItems>
              {hasStipend && (
                <TotalLine
                  label={miniCartCopy.stipendBalanceLabel}
                  price={formatPrettyCurrency(stipend * 100)}
                  {...trackEvent({
                    category: 'minicart',
                    action: 'snapshot',
                    label: 'stipend balance',
                    value: stipend,
                  })}
                />
              )}
              <TotalLine
                label={miniCartCopy.totalLabel}
                price={formattedPrice}
                loading={cartStatus === 'pending'}
                {...trackEvent({
                  category: 'minicart',
                  action: 'snapshot',
                  label: 'total',
                  value: cart.totalPrice,
                })}
              />

              {hasPortalItemsSelected && !!portal?.discountPercentage && (
                <PortalDiscountText>
                  {portal?.name} {portal?.discountPercentage}
                  {miniCartCopy.portalDiscountMessage}
                </PortalDiscountText>
              )}
            </TotalLineItems>
            <IntlMsgWrap>
              <IntlShippingAndTaxMsg />
            </IntlMsgWrap>
          </TotalSection>
        )}

        {cart && (
          <CheckoutButtonWrapper>
            {checkoutButton}
            {checkoutFailed && (
              <CheckoutErrorMessage>{miniCartCopy.checkoutErrorMessage}</CheckoutErrorMessage>
            )}
          </CheckoutButtonWrapper>
        )}
      </TotalAndCheckout>
      {isRevealed && (
        <LazyHydrate whenVisible>
          <Upsells />
        </LazyHydrate>
      )}
    </>
  )
}
