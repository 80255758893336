'use client'

import parse, {
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from 'html-react-parser'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { SelectMark } from '@syconium/little-miss-figgy/dist/components/Icons/Icon/SelectMark'
import { fromMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'
import { timingSlower } from '@syconium/little-miss-figgy/dist/constants/timing'
import { focusOutline, focusOutlineInset } from '@syconium/little-miss-figgy/dist/utility-classes'

import { trackEvent } from '../../../lib/analytics/analytics'
import { cookieKeys } from '../../_config/Cookies.config'
import { useCookies } from '../../_providers/CookiesProvider.client'
import { PageSectionContent } from '../pages/PageSection.client'

const promoBarHeightMobilePixels = 30
const promoBarHeightDesktopPixels = 40
const slideTransitionDurationInMs = 400
const slideDurationInMs = 4000

const getParseOptions = (tabIndex?: number) => {
  const results: HTMLReactParserOptions = {
    replace: domNode => {
      if (domNode instanceof Element && domNode.name === 'a') {
        const { attribs, children } = domNode
        // @ts-ignore TODO: Handle the fact that data is not a known property
        const label: string = (children && children[0]?.data) ?? ''
        const parseOptions = getParseOptions(tabIndex)

        return (
          <>
            {/* TODO: Handle this auto disabled ESLint rule. */}
            {/* eslint-disable-next-line rulesdir/require-routing-service */}
            <a
              tabIndex={tabIndex}
              {...attributesToProps(attribs)}
              {...trackEvent({ category: 'promo bar', action: 'click link', label })}
            >
              {/** @ts-ignore TODO: Narrow the children type of ChildNode[] to the more narrow type of DomNode[] */}
              {children && domToReact(children, parseOptions)}
            </a>
          </>
        )
      }
      return
    },
  }

  return results
}

const isDuplicateIdBearingObject = (pages: Array<{ id: string }>, index: number) => {
  if (index === 0) {
    return pages[0]?.id === pages[pages.length - 1]?.id
  }
  return pages[index]?.id === pages[index - 1]?.id
}

const StyledPromoBar = styled.aside<{
  $backgroundColor: string
  $textColor: string
}>`
  background-color: ${o => o.$backgroundColor};
  color: ${o => o.$textColor};
  font-size: 10px;
  font-weight: 600;
  height: ${promoBarHeightMobilePixels}px;
  line-height: 14px;
  overflow: hidden;
  position: relative;
  transition: opacity ${timingSlower} ease;
  z-index: ${o => o.theme.zIndex.sticky};

  ${fromMd} {
    font-size: 13px;
    height: ${promoBarHeightDesktopPixels}px;
    line-height: 17px;
  }

  a {
    text-decoration: underline;
    ${focusOutline};
  }

  a:hover {
    text-decoration: none;
  }
`

type StyledPromoBarContentProps = {
  className?: string
  children?: React.ReactNode
}

const StyledPromoBarContent = styled(({ className, children }: StyledPromoBarContentProps) => {
  return <PageSectionContent className={className} children={children} />
})`
  display: flex;
  height: 100%;
  max-height: 100%;
`

const StyledPromoBarPage = styled.li`
  align-items: center;
  display: flex;
  height: ${promoBarHeightMobilePixels}px;
  justify-content: center;
  min-width: 100%;
  overflow: hidden;
  text-align: center;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;

  ${fromMd} {
    height: ${promoBarHeightDesktopPixels}px;
  }
`

const StyledPromoBarPages = styled.ul<{
  $displayPagesLength: number
  $transition: boolean
}>`
  display: flex;
  height: ${o => promoBarHeightMobilePixels * o.$displayPagesLength}px;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  ${StyledPromoBarPage} {
    transform: ${o =>
      o.$transition ? `translateY(-${promoBarHeightMobilePixels}px)` : 'translateY(0)'};
    transition: transform ${o => (o.$transition ? slideTransitionDurationInMs : 0)}ms ease-in-out;
  }

  ${focusOutlineInset};

  ${fromMd} {
    height: ${o => promoBarHeightDesktopPixels * o.$displayPagesLength}px;
  }
`

const StyledPromoBarCloseContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;

  ${fromMd} {
    right: 10px;
    top: 10px;
  }
`

const StyledPromoBarCloseButton = styled.button`
  line-height: 0;
  padding: 5px;
  ${focusOutline};

  &:hover g {
    color: ${o => o.theme.color.deprecated.obsidianLight};
  }
`

type PromoBarProps = {
  backgroundColor: string
  textColor: string
  pages: Array<{
    id: string
    content: string
  }>
  className?: string
}

export const PromoBar = ({ backgroundColor, textColor, className, pages }: PromoBarProps) => {
  const [cookies, setCookie] = useCookies([cookieKeys.promoBarDismissed.key])
  const isDismissed = cookies['figs-promo-bar-dismissed'] === 'true'

  const [transition, setTransition] = useState(false)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const autoTransition = pages.length > 1
  const lastPageIndex = pages.length - 1
  const nextPageIndex = currentPageIndex + 1 > lastPageIndex ? 0 : currentPageIndex + 1

  // Visually render the next and previous page (if it exists) only
  const displayPages = [pages[currentPageIndex]!, pages[nextPageIndex]!].filter(Boolean)

  const displayPagesLength = pages.length

  const handleNextPage = () => {
    setCurrentPageIndex(prev => (prev + 1) % displayPagesLength)
  }

  const handlePrevPage = () => {
    setCurrentPageIndex(prev => (prev - 1 + displayPagesLength) % displayPagesLength)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case 'ArrowUp':
        handlePrevPage()
        event.preventDefault()
        break
      case 'ArrowDown':
        handleNextPage()
        event.preventDefault()
        break
      default:
        break
    }
  }

  const [isHover, setIsHover] = useState(false)

  const onTransition = useCallback(() => {
    if (transition || isHover) return
    setTransition(true)
    setTimeout(() => {
      setCurrentPageIndex(nextPageIndex)
      setTransition(false)
    }, slideTransitionDurationInMs)
  }, [isHover, transition, nextPageIndex])

  const onCloseButtonClick = () => {
    setCookie('figs-promo-bar-dismissed', 'true')
  }

  useEffect(() => {
    if (!autoTransition) return
    const interval = setInterval(() => onTransition(), slideDurationInMs)
    return () => {
      clearInterval(interval)
    }
  }, [onTransition, autoTransition])

  if (isDismissed) return null

  return (
    <StyledPromoBar
      $backgroundColor={backgroundColor}
      $textColor={textColor}
      className={className}
      onMouseEnter={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
      onFocus={() => setIsHover(true)}
      onBlur={() => setIsHover(false)}
      onKeyDown={event => handleKeyDown(event)}
      id={'geFreeShippingBannerContainer'}
    >
      <StyledPromoBarContent>
        <StyledPromoBarPages
          $transition={transition}
          $displayPagesLength={displayPagesLength}
          tabIndex={0}
        >
          {displayPages.map((page, index) => {
            const isDuplicate = isDuplicateIdBearingObject(displayPages, index)
            const tabIndex = isDuplicate ? -1 : undefined
            const parseOptions = getParseOptions(tabIndex)

            return (
              <StyledPromoBarPage key={`${page.id}.${index}`}>
                {parse(page.content, parseOptions)}
              </StyledPromoBarPage>
            )
          })}
        </StyledPromoBarPages>
        <StyledPromoBarCloseContainer>
          <StyledPromoBarCloseButton onClick={onCloseButtonClick} value='close'>
            <SelectMark aria-label='close' height='10px' stroke='currentColor' width='10px' />
          </StyledPromoBarCloseButton>
        </StyledPromoBarCloseContainer>
      </StyledPromoBarContent>
    </StyledPromoBar>
  )
}
