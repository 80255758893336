'use client'

import { FormikHelpers } from 'formik'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { FlagIconImage } from '@syconium/little-miss-figgy/dist/components/FlagIconImage'
import { ScreenReaderOnly } from '@syconium/little-miss-figgy/dist/components/ScreenReaderOnly'
import { SelectOption } from '@syconium/little-miss-figgy/dist/components/Select/Select'
import { TextLink } from '@syconium/little-miss-figgy/dist/components/TextLink'
import {
  SupportedLocaleIdentifier,
  SupportedRegionIdentifier,
  languageNamesByLocale,
  supportedRegions,
} from '@syconium/weeping-figs'

import { gql } from '../../../__generated__/graphql/catalog'
import { useNextQuery } from '../../_providers/GraphqlClientsProvider.client'
import { useLocalization } from '../../_providers/LocalizationProvider.client'
import {
  NextDialogBody,
  NextDialogFooter,
  NextDialogHeader,
  NextDialogTitle,
} from '../dialogs/NextDialog.client'
import { Form } from '../form/Form'
import { FormFieldSkeleton } from '../form/FormFieldSkeleton'
import { FormSelect } from '../form/FormSelect'
import { FormSubmit } from '../form/FormSubmit'
import { NextLink } from '../navigation/NextLink'

const StyledLocalizationForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${o => o.theme.spacing(5)};
  text-align: left;
  min-width: 280px;
`

type ChangeLocaleFormValues = {
  locale: SupportedLocaleIdentifier
  region: SupportedRegionIdentifier
}

const SkeletonLocalizationForm = () => {
  return (
    <>
      <FormFieldSkeleton />
      <FormFieldSkeleton />
      <FormFieldSkeleton excludeLabel />
    </>
  )
}

type LocalizationDialogContentProps = {
  formText: {
    titleText: string
    countryLabelText: string
    languageLabelText: string
    submitButtonText: string
    submittingText: string
    loadingText: string
  }
} & (
  | {
      variant: 'full'
      headerText: {
        closeText: string
      }
      footerText: {
        figsPrivacyLinkText: string
        gePrivacyLinkText: string
      }
    }
  | {
      variant: 'form-only'
      headerText?: never
      footerText?: never
    }
)

const LocalizationDialogContent = ({
  variant,
  formText,
  headerText,
  footerText,
}: LocalizationDialogContentProps) => {
  const { data, loading } = useNextQuery(
    gql(`
      query GetLocalizationOptionsDialog {
        siteFixtures(handle: "default") {
          id
          __typename
          localeData {
            locales {
              locale
              language
            }
          }
          regionData {
            id
            __typename
            regions {
              id
              __typename
              flagSrc
              name
            }
          }
        }
      }
    `)
  )

  const { region: currentRegion, locale: currentLocale, changeLocale } = useLocalization()
  const [selectedRegion, setSelectedRegion] = useState(currentRegion)

  const flagSrc =
    data?.siteFixtures.regionData?.regions.find(region => region.id === selectedRegion.id)
      ?.flagSrc ?? selectedRegion.flagSrc

  const regionOptions = useMemo(() => {
    return Object.entries(supportedRegions)
      .map(([_, supportedRegion]) => {
        return {
          ...supportedRegion,
          localizedName:
            data?.siteFixtures.regionData?.regions.find(region => region.id === supportedRegion.id)
              ?.name ?? supportedRegion.name,
        }
      })
      .filter(
        supportedRegion => supportedRegion.isEnabled || supportedRegion.id === currentRegion.id
      )
      .sort((a, b) => a.localizedName.localeCompare(b.localizedName))
  }, [currentRegion.id, data])

  const localeOptions = useMemo(() => {
    return Object.entries(selectedRegion.languages)
      .map(([language, regionalLanguage]) => {
        return {
          ...regionalLanguage,
          language,
          localizedLanguageName:
            data?.siteFixtures.localeData?.locales.find(
              locale => locale.locale === regionalLanguage.locale
            )?.language ?? languageNamesByLocale[regionalLanguage.locale],
        }
      })
      .filter(regionalLanguage => regionalLanguage.isEnabled)
      .sort((a, b) => a.localizedLanguageName.localeCompare(b.localizedLanguageName))
  }, [selectedRegion.languages, data])

  const initialValues = useMemo<ChangeLocaleFormValues>(() => {
    return {
      region: currentRegion.id,
      locale: currentLocale,
    }
  }, [currentLocale, currentRegion.id])

  const onSubmit = (
    values: ChangeLocaleFormValues,
    _actions: FormikHelpers<ChangeLocaleFormValues>
  ) => {
    changeLocale(
      regionOptions.find(regionOption => regionOption.id === values.region) ?? selectedRegion,
      values.locale
    )
  }

  const BodyElement = variant === 'full' ? NextDialogBody : 'div'

  return (
    <>
      {variant === 'full' ? (
        <NextDialogHeader closeText={headerText.closeText}>
          <div>
            <FlagIconImage src={flagSrc} />
          </div>
          <div>
            <NextDialogTitle>{formText.titleText}</NextDialogTitle>
          </div>
        </NextDialogHeader>
      ) : null}
      <BodyElement>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          ariaLabel={formText.titleText}
          children={({ getFieldHelpers, values }) => {
            return (
              <StyledLocalizationForm>
                {loading ? (
                  <>
                    <ScreenReaderOnly>{formText.loadingText}</ScreenReaderOnly>
                    <SkeletonLocalizationForm />
                  </>
                ) : (
                  <>
                    <div>
                      <FormSelect
                        label={formText.countryLabelText}
                        name='region'
                        onChange={newRegionId => {
                          const newSelectedRegion = regionOptions.find(
                            regionOption => regionOption.id === newRegionId
                          )
                          const localeFieldHelpers = getFieldHelpers('locale')
                          localeFieldHelpers.setValue(
                            newSelectedRegion?.defaultLocale ?? values.locale
                          )
                          if (newSelectedRegion) setSelectedRegion(newSelectedRegion)
                        }}
                        renderSelectValue={newRegionId => {
                          return (
                            regionOptions.find(regionOption => regionOption.id === newRegionId)
                              ?.localizedName ?? newRegionId
                          )
                        }}
                      >
                        {regionOptions.map(regionOption => {
                          return (
                            <SelectOption key={regionOption.id} value={regionOption.id}>
                              {regionOption.localizedName}
                            </SelectOption>
                          )
                        })}
                      </FormSelect>
                    </div>

                    <div>
                      <FormSelect
                        label={formText.languageLabelText}
                        name='locale'
                        renderSelectValue={selectedValue => {
                          return (
                            localeOptions.find(
                              localeOption => localeOption.locale === selectedValue
                            )?.localizedLanguageName ?? selectedValue
                          )
                        }}
                      >
                        {localeOptions.map(localeOption => {
                          return (
                            <SelectOption key={localeOption.locale} value={localeOption.locale}>
                              {localeOption.localizedLanguageName}
                            </SelectOption>
                          )
                        })}
                      </FormSelect>
                    </div>

                    <FormSubmit submittingLabel={formText.submittingText}>
                      {formText.submitButtonText}
                    </FormSubmit>
                  </>
                )}
              </StyledLocalizationForm>
            )
          }}
        />
      </BodyElement>
      {variant === 'full' ? (
        <NextDialogFooter>
          <div>
            <TextLink as={NextLink} color='secondary' href='/pages/non-us-privacy-policy'>
              {footerText.figsPrivacyLinkText}
            </TextLink>
          </div>
          <div>
            <TextLink
              color='secondary'
              href='https://s3.global-e.com/merchantscontent/Figs/docs/FIGS_Privacy_Policy--2022_06_03.pdf'
            >
              {footerText.gePrivacyLinkText}
            </TextLink>
          </div>
        </NextDialogFooter>
      ) : null}
    </>
  )
}

const LocalizationMenu = styled.div`
  display: flex;
  align-items: center;
  gap: ${o => o.theme.spacing(2)};
`

export {
  LocalizationDialogContent as PrimaryNavLocalizationDialogContent,
  LocalizationMenu as PrimaryNavLocalizationMenu,
}
