import { useQuery } from '@apollo/client'
import { useEffect } from 'react'

import { FETCH_SITE_FIXTURES } from '@syconium/magnolia/src/lib/graphql/queries'

import { reportClientError } from '../../../../app/_components/chrome/scripts/DataDogRumScript'
import { usePreviewDirectives } from '../../../../app/_providers/PreviewDirectivesProvider.client'

/**
 * @private useCmsProvidedSiteFixtures
 *
 * This is only supposed to be used by FixturesContext.
 * Do not use it elsewhere in the app. This will result in unnecessary renders.
 *
 * useState for properties with fallback on error.
 * No state is needed for fields with no fallback.
 */
export function useCmsProvidedSiteFixtures() {
  const { includeUnpublishedCmsContent } = usePreviewDirectives()

  const { data, previousData, error } = useQuery(FETCH_SITE_FIXTURES, {
    variables: { handle: 'default', preview: includeUnpublishedCmsContent },
  })

  // Fallback to use previously fetched fixture data if `currentMenuVariation` observable changes
  // to avoid undefined behavior during loading of new fixtures
  const siteFixturesData = data?.siteFixtures ?? previousData?.siteFixtures

  const {
    embroideryIcon = null,
    embroideryWarningMessage = null,
    enablePDPAccentColor = null,
    localeData = null,
    miniCartMessage = null,
    promoBar = null,
    siteWidePromo = null,
    enableSiteWidePromo = null,
    tooltips = null,
    regionData = null,
    logoColorSelectionTooltip = null,
    logoDigitizationDisclaimer = null,
    logoDigitizationWarning = null,
    logoLearnMorelink = null,
    logoDigitizationRequestedTooltip = null,
  } = siteFixturesData ?? {}

  const footer = siteFixturesData?.footer

  const navigation = siteFixturesData?.navigation

  useEffect(() => {
    if (error) {
      reportClientError({
        error,
        context: {
          scope: 'useCmsProvidedSiteFixtures',
          label: 'error fetching SiteFixtures from Contentful',
        },
      })
    }
  }, [error])

  return {
    embroideryIcon,
    embroideryWarningMessage,
    enablePDPAccentColor,
    localeData,
    miniCartMessage,
    promoBar,
    regionData,
    siteWidePromo: enableSiteWidePromo ? siteWidePromo : null,
    tooltips,
    logoColorSelectionTooltip,
    logoDigitizationDisclaimer,
    logoDigitizationWarning,
    logoLearnMorelink,
    logoDigitizationRequestedTooltip,
    footer,
    navigation,
  }
}
