import styled from 'styled-components'

import { TypeStyle, fromMd, untilMd } from '@syconium/little-miss-figgy'

// Reach localizes prices in span.money
export const Price = styled.span.attrs({
  className: 'money',
})<{ $isSalePrice?: boolean; $displayDiscountAsValue?: boolean }>`
  ${TypeStyle.css.headlineExtraExtraSmall};

  align-items: center;
  color: ${o =>
    o.$isSalePrice && !o.$displayDiscountAsValue
      ? o.theme.color.deprecated.red.dark
      : o.theme.color.deprecated.obsidian};
  display: flex;
  justify-content: center;
  margin-inline-start: 8px;
  font-weight: 600;
`

export const PriceColumn = styled(Price)<{
  $displayDiscountAsValue: boolean
}>`
  ${untilMd} {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
  }

  align-items: ${o => (o.$displayDiscountAsValue ? 'flex-end' : 'center')};
  display: flex;
  flex-direction: column;
`

export const FullPrice = styled(Price)`
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  text-decoration: line-through;
`

export const ValuePrice = styled(Price)`
  color: ${o => o.theme.color.deprecated.stoneDark};
`

export const DeleteIconColumn = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-inline-start: 6px;

  ${fromMd} {
    margin-inline-start: 12px;
  }
`

export const DeleteIconButton = styled.button`
  display: flex;
  background: unset;
  border: unset;
  cursor: pointer;
  padding: 0;
`
