import { CSSProperties, ComponentProps } from 'react'
import styled from 'styled-components'

import { Button as LMFButton } from '@syconium/little-miss-figgy'

/**
 * Logic:
 *   - if isHighlighted (formerly isSoldOut), show black on white with border.
 *     on hover, no change.
 *   - else if isProcessing, show grey background and dark text.
 *     on hover, no change.
 *   - else if accentColor, use that background and white text.
 *     on hover, change background and border color to accentColor with 85% opacity.
 *   - else use selected buttonVariant color scheme (undefined here, to inherit)
 *     on hover, use selected buttonVariant hover color scheme.
 */
type StyledCtaButtonProps = ComponentProps<typeof LMFButton> & {
  accentColor?: CSSProperties['color']
  isHighlighted?: boolean
  isProcessing?: boolean
}

export const StyledCtaButton = styled(
  ({
    accentColor: _accentColor,
    isHighlighted: _isHighlighted,
    isProcessing: _isProcessing,
    ...lmfButtonProps
  }: StyledCtaButtonProps) => {
    return <LMFButton {...lmfButtonProps} />
  }
)`
  ${({ isHighlighted, isProcessing, accentColor, disabled, theme }) => {
    const disableHover = disabled || isHighlighted || isProcessing
    return `
      background-color: ${
        isHighlighted
          ? 'white'
          : isProcessing || disabled
          ? theme.color.deprecated.stoneLight
          : accentColor
      };
      border-color: ${
        isHighlighted
          ? 'black'
          : isProcessing || disabled
          ? theme.color.deprecated.stoneLight
          : accentColor
      };
      color: ${
        isHighlighted
          ? 'black'
          : isProcessing || disabled
          ? theme.color.deprecated.obsidianLighter
          : accentColor
          ? 'white'
          : undefined
      };

      &:hover {
        ${
          !(isProcessing || disabled)
            ? `background-color: ${
                disableHover ? 'initial' : accentColor ? getHoverColor(accentColor) : undefined
              };
                  border-color: ${
                    disableHover ? 'initial' : accentColor ? getHoverColor(accentColor) : undefined
                  };
                  color: ${disableHover ? 'initial' : accentColor ? 'white' : undefined};`
            : undefined
        }
      }
    `
  }}
`

export const LoadingSpinnerWrapper = styled.div`
  margin-right: 15px;
`

// Convert CSS color from 6-digit to 8-digit hex with 85% opacity
const getHoverColor = (x: CSSProperties['color']) =>
  /^#.{6}$/.test(x?.toString() ?? '') ? `${x}d9` : x
