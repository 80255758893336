import { TextLink } from '@syconium/little-miss-figgy/dist/components/TextLink'

import { gql } from '../../../__generated__/graphql/catalog/gql'
import { NavAccordionSectionFragmentFragment } from '../../../__generated__/graphql/catalog/graphql'
import { DisclosureSummaryCaratIcon } from '../disclosures/Disclosure.client'
import {
  DisclosureList,
  DisclosureListItem,
  DisclosureListItemContent,
  DisclosureListItemLink,
  DisclosureListItemSummary,
} from '../disclosures/DisclosureList.client'
import { NextLink } from '../navigation/NextLink'

import { PrimaryNavContentfulSection } from './PrimaryNavContentfulSections.client'
import {
  PrimaryNavItem,
  PrimaryNavItemItemBadge,
  PrimaryNavItemItemIcon,
} from './PrimaryNavItemListSection.client'

export const navAccordionSectionQueryFragment = gql(`
  fragment NavAccordionSectionFragment on NavAccordion {
    __typename
    id
    variant
    sections {
      __typename
      id
      header {
        ...NavListSectionItemFieldsFragment
      }
      body {
        __typename
        ... on NavListSection {
          ...NavListSectionFragment
        }
        ... on NavTileSection {
          ...NavTileSectionFragment
        }
      }
    }
  }
`)

export const PrimaryNavAccordionSection = ({ sections }: NavAccordionSectionFragmentFragment) => {
  return (
    <DisclosureList>
      {sections.map(section => {
        const { badge, badgeColor, badgeTextColor, href, icon, text, textColor } = section.header

        const textStyle = { color: textColor ?? undefined }

        const navItem = (
          <PrimaryNavItem style={textStyle} asTag='span' $variant='heading'>
            {icon ? (
              <PrimaryNavItemItemIcon
                alt=''
                src={icon}
                aspectRatios={{ sm: 1, md: 1 }}
                widths={{ unit: 'px', sm: 16, md: 16 }}
                role='presentation'
                transparentBackground={true}
                loading='lazy'
              />
            ) : null}
            <span>{text}</span>
            {badge ? (
              <PrimaryNavItemItemBadge
                asTag={'span'}
                $badgeColor={badgeColor}
                $badgeTextColor={badgeTextColor}
              >
                {badge}
              </PrimaryNavItemItemBadge>
            ) : null}
          </PrimaryNavItem>
        )

        if (
          section.body?.__typename === 'NavListSection' ||
          section.body?.__typename === 'NavTileSection'
        ) {
          return (
            <DisclosureListItem key={section.id}>
              <DisclosureListItemSummary renderIcon={<DisclosureSummaryCaratIcon />}>
                {navItem}
              </DisclosureListItemSummary>
              <DisclosureListItemContent>
                <PrimaryNavContentfulSection {...section.body} />
              </DisclosureListItemContent>
            </DisclosureListItem>
          )
        }

        if (href) {
          return (
            <DisclosureListItem key={section.id}>
              <DisclosureListItemLink as='div'>
                <TextLink href={href} as={NextLink} variant='underline-on-hover' inheritTypography>
                  {navItem}
                </TextLink>
              </DisclosureListItemLink>
            </DisclosureListItem>
          )
        }

        return null
      })}
    </DisclosureList>
  )
}
