'use client'

import { Focusable } from '@ariakit/react'
import { ComponentProps, useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import styled from 'styled-components'

import { IconButton } from '@syconium/little-miss-figgy/dist/components/IconButton/IconButton'
import { Search } from '@syconium/little-miss-figgy/dist/components/Icons/Icon/Search'
import { ScreenReaderOnly } from '@syconium/little-miss-figgy/dist/components/ScreenReaderOnly'
import { TextLink } from '@syconium/little-miss-figgy/dist/components/TextLink'
import {
  TypeStyleBodyLarge,
  TypeStyleBodyMicro,
  TypeStyleHeadlineExtraExtraSmall,
} from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'
import { timingDefault } from '@syconium/little-miss-figgy/dist/constants/timing'
import { useIsViewport } from '@syconium/little-miss-figgy/dist/lib/hooks/useIsViewport'
import { ViewportSizes } from '@syconium/little-miss-figgy/dist/lib/hooks/useViewportBreakpoints'

import { gql } from '../../../__generated__/graphql/catalog/gql'
import { trackEvent } from '../../../lib/analytics/analytics'
import { useGoogleTagManager } from '../../../lib/hooks/useGoogleTagManager/useGoogleTagManager'
import { useInternationalAvailability } from '../../_hooks/useInternationalAvailability'
import { useNextQuery } from '../../_providers/GraphqlClientsProvider.client'
import { useLocalization } from '../../_providers/LocalizationProvider.client'
import { useTrackProductInteraction } from '../../_providers/TrackingProvider.client'
import { NextLink } from '../navigation/NextLink'
import {
  PriceRangeForProductGroup,
  derivePricingForProductGroup,
} from '../pricing/PriceRangeForProductGroup.client'
import { TextHighlighting } from '../text/TextHighlighting.client'
import {
  ProductTileContentHorizontalLayout,
  ProductTileImage,
  ProductTileImageSkeleton,
  ProductTilePriceSkeleton,
  ProductTileTitleSkeleton,
} from '../tiles/ProductTile.client'
import { Tile } from '../tiles/Tiles.client'

import { primaryNavHeaderMinHeightPixels } from './PrimaryNavHeader.client'
import { PrimaryNavMenuDialog, PrimaryNavMenuDialogTrigger } from './PrimaryNavMenus.client'

const navSearchAutocompleteQuery = gql(`
  query PrimaryNavSearchAutocomplete($first: Int!, $query: String!) {
    search(query: $query) {
      suggestions {
        query
      }
      items(first: $first) {
        numItems
        nodes {
          product {
            id
            shopifyId
            title
            handle
            category
            productGroup {
              handle
              rawFits
              priceRange {
                min
                max
              }
            }
            colorInfo {
              rawName
            }  
            images(first: 1) {
              nodes {
                source
              }
            }
            defaultVariant {
              sku
              priceDetails {
                discountPrice {
                  amount
                }
                price {
                  amount
                  currency
                }
              }
            }
          }
        }
      }
    }
  }
`)

type StyledSearchMenuButtonContentProps = {
  children?: React.ReactNode
  className?: string
  searchText: string
}

const StyledSearchMenuButtonContent = styled(
  ({ searchText, ...props }: StyledSearchMenuButtonContentProps) => {
    return (
      <div {...props}>
        <Search />
        {searchText}
      </div>
    )
  }
)`
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${o => o.theme.color.input.empty.border.on.background};
  background-color: ${o => o.theme.color.input.empty.fill.on.background};
  color: ${o => o.theme.color.input.empty.content.on.background};
  box-sizing: border-box;
  display: flex;
  font-size: 13px;
  line-height: 14px;
  font-weight: 600;
  padding: ${o => o.theme.spacing(0.5)} ${o => o.theme.spacing(8)} ${o => o.theme.spacing(0.5)}
    ${o => o.theme.spacing(2)};
  gap: ${o => o.theme.spacing(1)};

  &:hover,
  &:focus {
    border-color: ${o => o.theme.color.input.filled.border.on.background};
  }

  ${untilMd} {
    width: 100%;
    border-radius: 0px;
    border-width: 0px 0px 1px 0px;
    font-family: ${o => o.theme.typography.deprecated.font.family.primary};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.6px;
    line-height: 18px;
    color: ${o => o.theme.color.input.empty.content.on.background};
    margin-bottom: ${o => o.theme.spacing(4)};
  }
`

export const PrimaryNavSearchMenuDialogTrigger = styled(
  ({
    children,
    searchText,
    ...props
  }: ComponentProps<typeof PrimaryNavMenuDialogTrigger> & StyledSearchMenuButtonContentProps) => {
    return (
      <PrimaryNavMenuDialogTrigger {...props}>
        <StyledSearchMenuButtonContent searchText={searchText}>
          {children}
        </StyledSearchMenuButtonContent>
      </PrimaryNavMenuDialogTrigger>
    )
  }
)`
  ${untilMd} {
    width: 100%;
  }
`

export const PrimaryNavSearchMenuDialog = ({
  gutter,
  ...props
}: ComponentProps<typeof PrimaryNavMenuDialog>) => {
  const isMediumOrLargeViewport = useIsViewport([ViewportSizes.medium, ViewportSizes.large], {
    ssr: false,
  })
  const desiredGutter =
    gutter ?? (!isMediumOrLargeViewport ? -primaryNavHeaderMinHeightPixels : undefined)
  return <PrimaryNavMenuDialog gutter={desiredGutter} {...props} />
}

export const PrimaryNavSearchMenuBody = styled.div`
  box-sizing: border-box;
  max-width: 993px;
  margin: 0px auto;
  display: block;
  width: 100%;

  ${fromMd} {
    width: 80%;
  }
`

const PrimaryNavSearchInputWrapper = styled.div<{
  $isInvalid: boolean
}>`
  position: relative;
  display: block;
  border-bottom: 1px solid ${o => o.theme.color.input.filled.border.on.background};
  border-bottom-color: ${o =>
    o.$isInvalid && o.theme.color.input.invalid.filled.border.on.background};
  transition: border-color ${timingDefault} ease;

  margin: 0px 12px 24px;
  ${fromMd} {
    margin: 32px 0px 56px;
    border-bottom-width: 2px;
  }
`

const PrimaryNavSearchInput = styled.input`
  border: none;
  box-sizing: border-box;
  font-family: ${o => o.theme.typography.deprecated.font.family.primary};
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  letter-spacing: 0.6px;
  line-height: 18px;
  outline: none;
  padding-top: 12px;
  padding-bottom: 0;
  padding-inline-end: 12px;
  padding-inline-start: 12px;
  width: 100%;

  ${fromMd} {
    font-size: 20px;
    padding: 0 32px;
  }

  color: ${o => o.theme.color.input.filled.content.on.background};
  ::placeholder {
    color: ${o => o.theme.color.input.empty.content.on.background};
  }
`

const PrimaryNavSearchSubmit = styled(
  ({
    flushBottom = true,
    flushLeft = true,
    flushRight = true,
    flushTop = true,
    type = 'submit',
    variant = 'black-on-transparent',
    borderless = true,
    ...props
  }: ComponentProps<typeof IconButton>) => {
    return (
      <IconButton
        flushBottom={flushBottom}
        flushLeft={flushLeft}
        flushRight={flushRight}
        flushTop={flushTop}
        type={type}
        variant={variant}
        borderless={borderless}
        {...props}
      />
    )
  }
)`
  position: absolute;
  bottom: 0px;
  right: 0px;
`

const StyledNoResultsText = styled(TypeStyleBodyLarge)`
  margin: 42px 0;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`

const StyledSearchResultsGrid = styled.ul`
  display: grid;

  gap: 16px;
  grid-template-columns: 1fr;
  margin: 20px 0px 8px;
  ${fromMd} {
    gap: 36px;
    grid-template-columns: 1fr 1fr;
    margin: 56px 0px 16px;
  }
`

const StyledSearchResultsSuggestions = styled.div`
  border-top: 2px solid ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
  margin: 16px 32px 28px;

  margin: 24px 0px 0px;
  padding: 24px 0 36px;
  ${fromMd} {
    border: none;
    padding: 28px 0 56px;
    margin: 28px 48px 0px;
    text-align: center;
  }
`

const StyledSearchResultsSuggestionsTitle = styled(
  ({
    as = 'h3',
    isUppercase = true,
    color = 'secondary',
    ...props
  }: ComponentProps<typeof TypeStyleBodyMicro>) => {
    return <TypeStyleBodyMicro color={color} as={as} isUppercase={isUppercase} {...props} />
  }
)`
  margin-bottom: 20px;
  text-transform: uppercase;
`

type PrimaryNavSearchFormProps = {
  searchText: string
  noResultsText: string
  suggestedSearchesTitleText: string
  numberOfResultsText: string
  previewSearchResultsOnType: boolean
}

export const PrimaryNavSearchForm = ({
  searchText,
  noResultsText,
  suggestedSearchesTitleText,
  numberOfResultsText,
  previewSearchResultsOnType,
}: PrimaryNavSearchFormProps) => {
  const { region } = useLocalization()
  const { filterUnavailableItemsFromArray } = useInternationalAvailability()
  const { pushSearchSubmitted } = useGoogleTagManager()
  const { trackProductInteraction } = useTrackProductInteraction()
  const [searchInputValue, setSearchInputValue] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState<string>(searchInputValue)
  const [getSearchQueryDebounceStatus] = useDebounce(
    () => {
      setSearchQuery(searchInputValue)
    },
    300,
    [searchInputValue]
  )
  const debouncing = getSearchQueryDebounceStatus() === false && searchQuery !== searchInputValue

  const { data, loading } = useNextQuery(navSearchAutocompleteQuery, {
    variables: {
      query: searchQuery,
      first: 6,
    },
    skip: searchQuery.length === 0,
  })

  const unfilteredProducts = useMemo(() => {
    if (searchQuery.length === 0) return []
    return (data?.search.items.nodes ?? []).map(node => {
      const product = node.product
      const baseUrl = `/${product.category === 'Product Kit' ? 'kits' : 'products'}/${
        product.productGroup.handle
      }`
      const searchParams = new URLSearchParams()
      if (product.colorInfo?.rawName) {
        searchParams.set('color', product.colorInfo.rawName)
      }
      if (product.productGroup.rawFits[0]) {
        searchParams.set('fit', product.productGroup.rawFits[0])
      }
      const url = `${baseUrl}?${searchParams.toString()}`
      return {
        ...product,
        url,
        image: product.images?.nodes[0],
        pricing: product.defaultVariant.priceDetails?.price
          ? derivePricingForProductGroup({
              products: [
                {
                  priceRange: product.productGroup.priceRange,
                  defaultVariant: {
                    price: product.defaultVariant.priceDetails.price.amount,
                    discountPrice: product.defaultVariant.priceDetails.discountPrice?.amount,
                  },
                },
              ],
            })
          : undefined,
      }
    })
  }, [data?.search.items.nodes, searchQuery.length])

  const products = useMemo(() => {
    return filterUnavailableItemsFromArray({
      items: unfilteredProducts,
      regionId: region.id,
    })
  }, [filterUnavailableItemsFromArray, region.id, unfilteredProducts])
  const skeletonProducts = useMemo(() => [0, 1, 2, 3, 4, 5], [])

  const status = useMemo(() => {
    if (searchQuery.length === 0 && !debouncing) return 'idle'
    if (loading || debouncing) return 'pending'
    if (products.length > 0) return 'resolved'
    return 'no-results'
  }, [debouncing, loading, products.length, searchQuery.length])

  const onFormSubmit = useCallback(() => {
    pushSearchSubmitted(searchInputValue)
  }, [pushSearchSubmitted, searchInputValue])

  return (
    <>
      <form autoComplete='off' noValidate role='search' action='/search' onSubmit={onFormSubmit}>
        <PrimaryNavSearchInputWrapper $isInvalid={false}>
          <Focusable
            focusable
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            render={
              <PrimaryNavSearchInput
                aria-label={searchText}
                name='q'
                placeholder={searchText}
                value={searchInputValue}
                onChange={event => {
                  setSearchInputValue(event.target.value)
                }}
              />
            }
          />
          <PrimaryNavSearchSubmit aria-label={searchText}>
            <Search aria-hidden='true' />
          </PrimaryNavSearchSubmit>
        </PrimaryNavSearchInputWrapper>
      </form>

      {previewSearchResultsOnType ? (
        <>
          <ScreenReaderOnly>
            <div role='status' aria-live='polite' aria-atomic='true' aria-relevant='all'>
              {status === 'resolved' && typeof data?.search.items.numItems === 'number'
                ? numberOfResultsText.replace('{numberOfResults}', `${data?.search.items.numItems}`)
                : null}
            </div>
          </ScreenReaderOnly>

          {status === 'no-results' ? (
            <StyledNoResultsText asTag='div'>
              {noResultsText.replace('{searchText}', searchQuery)}
            </StyledNoResultsText>
          ) : status === 'pending' ? (
            <StyledSearchResultsGrid as='ul'>
              {skeletonProducts.map((_, index) => {
                return (
                  <Tile as='li' key={index}>
                    <ProductTileContentHorizontalLayout>
                      <div style={{ width: '80px', flexShrink: 0, flexGrow: 0 }}>
                        <ProductTileImageSkeleton aspectRatio={'square'} />
                      </div>
                      <div style={{ flex: 1 }}>
                        <ProductTileTitleSkeleton />
                        <ProductTilePriceSkeleton />
                      </div>
                    </ProductTileContentHorizontalLayout>
                  </Tile>
                )
              })}
            </StyledSearchResultsGrid>
          ) : status === 'resolved' ? (
            <>
              <StyledSearchResultsGrid as='ul'>
                {products.map((product, index) => {
                  return (
                    <Tile
                      as='li'
                      key={`${product.id}${index}`}
                      {...trackEvent({
                        category: 'search',
                        action: 'click related search query',
                        label: product.url,
                      })}
                    >
                      <NextLink
                        href={product.url}
                        onClick={() => {
                          if (product.handle) {
                            trackProductInteraction({
                              handle: product.handle,
                              id: product.id,
                              shopifyId: product.shopifyId,
                            })
                          }
                        }}
                      >
                        <ProductTileContentHorizontalLayout>
                          <div style={{ width: '80px', flexShrink: 0, flexGrow: 0 }}>
                            {product.image ? (
                              <ProductTileImage
                                alt={product.title}
                                loading='lazy'
                                src={product.image.source}
                                aspectRatio={'square'}
                                widths={{
                                  unit: 'px',
                                  sm: 80,
                                  md: 80,
                                }}
                              />
                            ) : null}
                          </div>
                          <div style={{ flex: 1 }}>
                            <TypeStyleBodyMicro color='primary'>
                              <TextHighlighting text={product.title} targetText={searchQuery} />
                            </TypeStyleBodyMicro>
                            {product.pricing ? (
                              <TypeStyleBodyMicro color='primary'>
                                <PriceRangeForProductGroup
                                  {...product.pricing}
                                  upToPercentageOff={null}
                                  percentageOff={null}
                                />
                              </TypeStyleBodyMicro>
                            ) : null}
                          </div>
                        </ProductTileContentHorizontalLayout>
                      </NextLink>
                    </Tile>
                  )
                })}
              </StyledSearchResultsGrid>
              {data?.search?.suggestions && data.search.suggestions.length > 0 ? (
                <StyledSearchResultsSuggestions>
                  <StyledSearchResultsSuggestionsTitle>
                    {suggestedSearchesTitleText}
                  </StyledSearchResultsSuggestionsTitle>
                  <ul>
                    {data.search.suggestions.map((suggestion, index) => (
                      <TypeStyleHeadlineExtraExtraSmall as='li' key={index}>
                        <TextLink
                          variant='underline-on-hover'
                          inheritTypography
                          as={NextLink}
                          href={`/search?q=${suggestion.query}`}
                          {...trackEvent({
                            category: 'search 2.0',
                            action: 'click',
                            label: 'popular item',
                          })}
                        >
                          {suggestion.query}
                        </TextLink>
                      </TypeStyleHeadlineExtraExtraSmall>
                    ))}
                  </ul>
                </StyledSearchResultsSuggestions>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </>
  )
}
