import * as React from 'react'

import { Bag as BagIcon, DeprecatedText } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { useCartDialog } from '../../../../app/_providers/CartProvider.client'
import { useTranslation } from '../../../../app/_providers/TranslationProvider.client'
import { CartContainer } from '../../../containers/cart'

import { IProps } from './IProps'
import { Button } from './styles'

export const CartButton: React.FC<IProps> = ({ ...rest }) => {
  const {
    magnolia: { navigation: strings },
  } = useTranslation()
  const { cart } = CartContainer.useContainer()
  const { open: openCartDialog } = useCartDialog()
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  return (
    <Button
      ref={buttonRef}
      onClick={() => {
        openCartDialog()
      }}
      aria-label={strings.cartButtonAriaLabel}
      {...trackEvent({ category: 'navigation', action: 'open' })}
      {...rest}
    >
      <BagIcon />
      <DeprecatedText textType='tag' asTag='span'>
        {(cart && cart.itemCount) || 0}
      </DeprecatedText>
    </Button>
  )
}
