'use client'

import styled from 'styled-components'

import { ResponsiveImage } from '@syconium/little-miss-figgy/dist/components/Image/ResponsiveImage'
import { TextLink } from '@syconium/little-miss-figgy/dist/components/TextLink'
import { TypeStyleHeadlineMicro } from '@syconium/little-miss-figgy/dist/components/TypeStyle'

import { gql } from '../../../__generated__/graphql/catalog'
import { NavTileSectionFragmentFragment } from '../../../__generated__/graphql/catalog/graphql'
import { trackEvent } from '../../../lib/analytics/analytics'
import { isUppercase } from '../../../lib/utils'
import { NextLink } from '../navigation/NextLink'

export const navTileSectionQueryFragment = gql(`
  fragment NavTileSectionFragment on NavTileSection {
    __typename
    id
    tiles {
      __typename
      id
      ctaText
      href
      image
    }
  }
`)

const StyledNavTiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(4)};
  max-width: 270px;
`

const StyledNavTile = styled.figure`
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(2)};
`

type PrimaryNavTileSectionProps = NavTileSectionFragmentFragment

export const PrimaryNavTileSection = ({ tiles }: PrimaryNavTileSectionProps) => {
  return (
    <StyledNavTiles>
      {tiles.map(tile => {
        return (
          <NextLink
            key={tile.id}
            href={tile.href}
            {...trackEvent({
              action: 'click tile',
              category: 'navigation',
              label: tile.ctaText,
              value: 'image',
            })}
          >
            <StyledNavTile as='figure'>
              <ResponsiveImage
                alt={tile.ctaText}
                loading='lazy'
                src={tile.image}
                widths={{
                  unit: 'px',
                  sm: 256,
                  md: 256,
                }}
                aspectRatios={{
                  sm: 2 / 1,
                  md: 2 / 1,
                }}
              />
              <TypeStyleHeadlineMicro as='figcaption' isUppercase={isUppercase(tile.ctaText)}>
                <b>
                  <TextLink as='div' variant='right-carat' inheritTypography>
                    {tile.ctaText}
                  </TextLink>
                </b>
              </TypeStyleHeadlineMicro>
            </StyledNavTile>
          </NextLink>
        )
      })}
    </StyledNavTiles>
  )
}
