import styled from 'styled-components'

import { ProgressiveAsset, TypeStyle } from '@syconium/little-miss-figgy'

export const Body = styled.div`
  padding: 6px 20px;
`

const imageSize = '100px'

export const Top = styled.div`
  display: flex;

  ${ProgressiveAsset.Styles.Container} {
    height: ${imageSize};
    width: ${imageSize};
  }

  ${ProgressiveAsset.Styles.Image} {
    background: ${o => o.theme.color.deprecated.stoneLighter};
  }
`

export const ImagePlaceholder = styled.div`
  background: ${o => o.theme.color.deprecated.stoneLighter};
  height: ${imageSize};
  width: ${imageSize};
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100px;
  justify-content: space-between;
  margin-inline-start: 12px;
`

export const Title = styled(TypeStyle.HeadlineExtraExtraSmall)`
  font-size: 13px;
`

export const OptionsText = styled(TypeStyle.BodySmall)`
  color: ${o => o.theme.color.deprecated.obsidianLighter};
`

export const FitDescription = styled.span`
  text-transform: capitalize;
`
